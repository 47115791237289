<template>
  <b-modal
    id="modal-asset"
    ref="refModal"
    :title="t('Tài sản')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    size="lg"
    no-close-on-backdrop
    :hide-header-close="true"
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Name -->
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Tên"
              >
                <template
                  v-slot:label
                >
                  {{ t('Tên') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Brand -->
          <b-col md="4">
            <b-form-group
              :label="t('Thương hiệu')"
            >
              <b-form-input
                id="brand"
                v-model="itemLocal.brand"
                trim
              />
            </b-form-group>
          </b-col>
          <!-- Color -->
          <b-col md="4">
            <b-form-group
              :label="t('Màu sắc')"
            >
              <b-form-input
                id="color"
                v-model="itemLocal.color"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Brand -->
          <b-col md="4">
            <b-form-group
              :label="t('Model/Năm sản xuất')"
            >
              <b-form-input
                id="model"
                v-model="itemLocal.model"
                trim
              />
            </b-form-group>
          </b-col>
          <!-- Made In -->
          <b-col md="4">
            <b-form-group
              :label="t('Xuất xứ')"
            >
              <b-form-input
                id="madeIn"
                v-model="itemLocal.madeIn"
                trim
              />
            </b-form-group>
          </b-col>
          <!-- Price -->
          <b-col md="4">
            <number-input
              v-model="itemLocal.price"
              :default-value="itemLocal.price"
              :label="t('Giá trị')"
            />

          </b-col>
        </b-row>

        <b-row>
          <!-- Quantity -->
          <b-col
            v-if="itemLocal.id === 0"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="quantity"
              rules="required|integer|positive"
            >
              <number-input
                v-model="itemLocal.quantity"
                :default-value="itemLocal.quantity"
                required
                :label="t('Số lượng')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>

          <!-- Status -->
          <b-col md="4">
            <b-form-group
              :label="t('Tình trạng')"
            >
              <b-form-input
                id="status"
                v-model="itemLocal.status"
                trim
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <select-date
              v-model="itemLocal.warranty"
              :label="t('Thời hạn bảo hành')"
              :default-value="itemLocal.warranty"
            />
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="provider"
              rules=""
            >
              <select-provider
                v-model="itemLocal.provider"
                :label="t('Nhà cung cấp')"
                :enable-add="true"
                :default-value="itemLocal.provider"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="asset_type"
              rules="required"
            >
              <select-asset-type
                v-model="itemLocal.type"
                required
                :enable-add="true"
                :label="t('Loại tài sản')"
                :default-value="itemLocal.type"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="warehouse"
              rules=""
            >
              <select-warehouse
                v-model="itemLocal.warehouse"
                :enable-add="true"
                :enable="!itemLocal.apartment"
                :label="t('Kho')"
                :default-value="itemLocal.warehouse"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules=""
            >
              <select-apartment
                v-model="itemLocal.apartment"
                :filter-active="true"
                :enable-add="true"
                :enable="!itemLocal.warehouse"
                :label="t('Tòa nhà')"
                :default-value="itemLocal.apartment"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="room"
              rules=""
            >
              <select-room
                v-model="itemLocal.room"
                :filter-active="true"
                :apartment="itemLocal.apartment"
                :enable-add="true"
                :label="t('Phòng')"
                :default-value="itemLocal.room"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- Brand -->
          <b-col md="4">
            <b-form-group
              :label="t('Vị trí')"
            >
              <b-form-input
                id="location"
                v-model="itemLocal.location"
                placeholder=""
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row />

        <!-- Note -->
        <b-form-group
          :label="t('Ghi chú')"
        >
          <b-form-textarea
            id="note"
            v-model="itemLocal.note"
            placeholder=""
            rows="3"
          />
        </b-form-group>
        <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
          {{ t('Hình ảnh tài sản') }}
        </div>
        <media-view
          v-model="itemLocal.attachments"
          :label="null"
          :initial-value="itemLocal.attachments"
          :upload-imediately="true"
          :target-object="{id: itemLocal.id, type: 'asset'}"
          :preview-mode="!$can('update', 'asset')"
          @on-delete-uploaded-file="onDeleteAttachment"
        />
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, integer, positive } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectProvider from '@/views/components/SelectProvider.vue';
import SelectAssetType from '@/views/components/SelectAssetType.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectWarehouse from '@/views/components/SelectWarehouse.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import MediaView from '@/views/components/media/MediaView.vue';
import useAssetHandler from './useAssetHandler';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    SelectProvider,
    SelectAssetType,
    SelectDate,
    SelectWarehouse,
    NumberInput,
    SelectApartment,
    SelectRoom,
    MediaView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      required,
      integer,
      positive,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();
    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onDeleteAttachment,
      onSubmit,
      t,
      onOpen,
      isLoading,
    } = useAssetHandler(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      onDeleteAttachment,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
      onOpen,
      isLoading,
    };
  },
};
</script>
