import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default function useMoveAsset(props, emit, refFormObserver) {
  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };
  const { t } = useI18nUtils();

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    try {
      const data = {
        assetIds: itemLocal.value.assets.map(obj => obj.id),
        note: itemLocal.value.note,
      };

      if (itemLocal.value.warehouse && itemLocal.value.warehouse.id > 0) {
        data.targetWarehouseId = itemLocal.value.warehouse.id;
      }
      if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
        data.targetApartmentId = itemLocal.value.apartment.id;
      }
      if (itemLocal.value.room && itemLocal.value.room.id > 0) {
        data.targetRoomId = itemLocal.value.room.id;
      }

      data.movingDate = parseDateToString(itemLocal.value.movingDate);

      isSubmitting.value = true;
      store
        .dispatch('asset/moveAsset', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-assets');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } catch (error) {
      toastification.showToastError(error);
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
  };
}
