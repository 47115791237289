import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProviders(ctx, params) {
      return useJwt.getProviders(params).then(response => response);
    },
    getProviderDetail(ctx, id) {
      return useJwt.getProviderDetail(id).then(response => response);
    },
    createProvider(ctx, data) {
      return useJwt.createProvider(data).then(response => response);
    },
    updateProvider(ctx, data) {
      return useJwt.updateProvider(data).then(response => response);
    },
    deleteProviders(ctx, data) {
      return useJwt.deleteProviders(data).then(response => response);
    },
  },
};
