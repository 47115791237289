<template>
  <div class="asset-list-container">
    <b-row
      v-if="analytics"
      class="match-height"
    >

      <b-col>
        <card-statistic
          :title="t('Tất cả')"
          :value="analytics.total"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>

      <b-col>
        <card-statistic
          :title="t('Trong kho')"
          :value="analytics.inWareHouse"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col>
        <card-statistic
          :title="t('Trong tòa nhà')"
          :value="analytics.inApartment"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col>
        <card-statistic
          :title="t('Vị trí chưa xác định')"
          :value="analytics.unLocated"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col>
        <card-statistic
          :title="t('Giá trị tài sản')"
          :value="Number(analytics.totalPrice).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Tài sản') }}</b-card-title>
        <crud-buttons
          :name="t('Tài sản')"
          modal="modal-asset"
          :selected-rows="selectedRows"
          :show-import="$can('create', 'asset')"
          :show-export="true"
          :hide-delete="!$can('delete', 'asset')"
          :enable-add="$can('create', 'asset')"
          @on-add="resetItem"
          @on-delete="onDelete"
          @download-template-file="downloadImportFile"
          @on-export="exportData"
          @on-import-file="importData"
        >
          <template
            slot="other-buttons"
          >
            <b-button
              v-b-tooltip.hover.v-warning
              v-b-modal.modal-move-asset
              :hidden="selectedRows.length === 0"
              variant="warning"
              class="btn-icon ml-50"
              :title="t('Di chuyển tài sản')"
              @click="onMoveItems(selectedRows)"
            >
              <feather-icon icon="MoveIcon" />
            </b-button>
          </template>
        </crud-buttons>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <select-apartment v-model="apartment" />
          </b-col>
          <b-col md="4">
            <select-room
              v-model="room"
              :apartment="apartment"
            />
          </b-col>

          <b-col md="4">
            <select-asset-type v-model="assetType" />
          </b-col>

        </b-row>
        <b-row>
          <b-col md="4">
            <select-provider v-model="provider" />
          </b-col>
          <b-col md="4">
            <select-warehouse v-model="warehouse" />
          </b-col>

          <b-col md="4">
            <b-form-input
              v-model="searchTerm"
              :placeholder="t('Tìm kiếm')"
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-asset-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'price'">
                  {{ Number(props.row.price).toLocaleString() }}
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'apartment.name'">
                  <span v-if="props.row.apartment">{{ props.row.apartment.name }}</span>
                  <span
                    v-if="props.row.room"
                    class="text-muted"
                  ><br>{{ props.row.room.name }}</span>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'default'">
                  <b-form-checkbox
                    :checked="props.row.default"
                    name="check-button"
                    switch
                    inline
                    disabled
                  />
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('Tài sản')"
                    modal="modal-asset"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button
                        v-b-tooltip.hover.v-warning
                        v-b-modal.modal-move-asset
                        variant="warning"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Di chuyển tài sản')"
                        @click="onMoveItems([props.row])"
                      >
                        <feather-icon icon="MoveIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- modal -->
    <asset-handler
      :item="item"
      :item-id="item ? item.id : null"
      @refetch-assets="fetchData"
    />

    <move-asset
      :item="item"
      @refetch-assets="fetchData"
    />
    <asset-detail-modal :asset-id="item.id" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,

  BFormCheckbox,
  BFormSelect,
  BSpinner,
  BButton,
  VBTooltip,
} from 'bootstrap-vue';

import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import SelectProvider from '@/views/components/SelectProvider.vue';
import SelectWarehouse from '@/views/components/SelectWarehouse.vue';
import SelectAssetType from '@/views/components/SelectAssetType.vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
// eslint-disable-next-line import/no-cycle
import CardStatistic from '@/views/components/CardStatistic.vue';
import useAssetList from './useAssetList';
// eslint-disable-next-line import/no-cycle
import AssetHandler from '../handler/AssetHandler.vue';
import MoveAsset from '../modal/MoveAsset.vue';
import AssetDetailModal from '../detail/AssetDetailModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormCheckbox,
    BFormSelect,
    BSpinner,
    BButton,

    AssetHandler,
    VueGoodTable,
    TableActions,
    CrudButtons,
    SelectProvider,
    SelectWarehouse,
    SelectAssetType,
    SelectApartment,
    SelectRoom,
    MoveAsset,
    CardStatistic,
    AssetDetailModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      item,
      analytics,
      resetItem,
      columns,
      rows,
      selectedRows,
      searchTerm,
      provider,
      warehouse,
      assetType,
      apartment,
      room,
      isLoading,
      totalRecords,
      serverParams,
      onMoveItems,
      fetchAssets,
      deleteAssets,
      createAsset,
      refetchAssets,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
      onDelete,
      fetchData,
      downloadImportFile,
      importData,
      exportData,
      t,
    } = useAssetList();

    return {
      item,
      analytics,
      resetItem,
      columns,
      rows,
      selectedRows,
      searchTerm,
      provider,
      warehouse,
      assetType,
      apartment,
      room,
      isLoading,
      totalRecords,
      serverParams,
      onMoveItems,
      refetchAssets,
      fetchAssets,
      deleteAssets,
      createAsset,
      fetchData,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
      onDelete,
      downloadImportFile,
      importData,
      exportData,
      t,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    showDeleteConfirmation(apartment) {
      let deleteObjects = [];
      let title = `Bạn đang thực hiện thao tác xoá ${this.selectedRows.length} nhà cung cấp. Bạn có chắc chắn muốn xoá không?`;
      if (apartment && apartment.id > 0) {
        deleteObjects = [apartment];
        title = `Bạn đang thực hiện thao tác xoá nhà cung cấp "${apartment.name}". Bạn có chắc chắn muốn xoá không?`;
      } else {
        deleteObjects = this.selectedRows;
      }

      this.$bvModal
        .msgBoxConfirm(title, {
          title: 'Xoá dịch vụ',
          okTitle: 'Xoá',
          cancelTitle: 'Huỷ',
          okVariant: 'danger',
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value) {
            this.deleteAssets(deleteObjects);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.asset-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 120px !important;

            &:first-child {
              min-width: 50px !important;
            }

            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(5) {
               min-width: 150px !important;
            }
            &:nth-child(6) {
               min-width: 150px !important;
            }
            &:nth-child(7) {
               min-width: 150px !important;
            }
            &:nth-child(8) {
               min-width: 200px !important;
            }
            &:nth-child(12) {
               min-width: 200px !important;
            }
          }
        }
      }
    }
}
</style>
